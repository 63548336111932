.switch-container {
     display: flex;
     align-items: center;
}

.switch {
     position: relative;
     display: inline-block;
     width: 70px;
     height: 34px;
}

.switch input[type="checkbox"] {
     opacity: 0;
     width: 0;
     height: 0;
}

.slider {
     position: absolute;
     cursor: pointer;
     top: 0;
     left: 0;
     right: 0;
     bottom: 0;
     background-color: #ccc;
     transition: 0.4s;
     border-radius: 34px;
}

.slider:before {
     position: absolute;
     content: "";
     height: 25px;
     width: 25px;
     left: 5px;
     bottom: 5px;
     background-color: white;
     transition: 0.4s;
     border-radius: 50%;
}

input[type="checkbox"]:checked + .slider {
     background-color: #e1aeff;
}

input[type="checkbox"]:checked + .slider:before {
     transform: translateX(27px);
     background-color: #66347f;
}

span {
     margin-left: 8px;
}

@media screen and (max-width: 600px) {
     .switch {
          width: 50px;
          height: 25px;
     }
     input[type="checkbox"]:checked + .slider:before {
          transform: translateX(18px);
     }
     .slider:before {
          height: 15px;
          width: 15px;
     }
}
